<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes=" table-responsive">
            <template slot="header">
              <div class="row">
                  <div class="col-6">
                      <h4 class="card-title">{{$t('Usuarios')}}</h4>
                  </div>
                   <!--<div class="col-6 d-flex justify-content-end">
                       <router-link to="user" class="btn bg-dark text-white font-weight-bold py-2 ">
                           <i class="fas fa-plus"></i>
                           <span class="font-weight-bold px-2">
                                {{$t("Agregar")}}
                            </span> 
                       </router-link>
                  </div>-->
              </div>
            </template>
            <l-table class="pl-4 table-hover table-striped"
                     :columns="table.columns"
                     :section="section"
                     :data="tableData">
            </l-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { apiCallList } from '../../mixins'

  export default {
    mixins: [apiCallList],
    components: {
      
    },
    data () {
      return {
        endpoint : "user",
        section  : "user",
        tableData : [],
        table: {
          columns: ['Id', 'name', 'Email','Rol','edit'],
        }
      }
    },
    methods : {
        loadItems(){
            this.data.forEach((item) => {
              item.rol = item.roles ? item.roles.name : "";
            });
            this.tableData = this.data;
            setTimeout(() => {
              window.$(".table").dataTable({
                pageLength: 25,
                language : {
                  url : '//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json'
                }
              });
            },100);
        }
    }
  }
</script>
<style>
</style>